/*
 * `ng build --configuration=qa` in `CM frontend` pipeline npm `build QA` step
 * not currently using the `qa` config within angular.json as expected.
 * Until further notice, assume DEV and QA deployments will use shared environment.ts (default, non-custom non-prod config)
 */

export const environment = {
    production: false,
    envName: 'qa',
    customerCreationEnabled: true,
    customerProfileUpdateEnabled: true,
    routeGuardsEnabled: false,
    parentChildEnabled: false
};
