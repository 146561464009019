import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from 'src/app/shared/modals/error-modal/error-modal.component';
import { AppInsightsService } from './appinsights.service';
import { CmAppStore } from './cm-app-store';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private modalService: NgbModal,
    private store: CmAppStore,
    private appInsights: AppInsightsService
  ) { }

  errorChooser(error: any) {
    this.appInsights.logTrace(`ErrorHandlerService error: ${JSON.stringify(error)}`);
    this.store.clearLoadIndicator();
    if (typeof error.error === 'object') {
      if (error.status === 403) {
        this.openErrorModal('Access Denied');
      }
      if (error.status !== 200) { // 200 is a success code
        this.openErrorModal('An API error has occurred. Please try again. If the problem persists, please submit an HDFS ticket.');
      }
    } else {
      this.openErrorModal(error.error);
    }
  }

  openErrorModal(error: string) {
    const modalRef = this.modalService.open(ErrorModalComponent, {
      ariaLabelledBy: 'modal-basic-title', backdrop: 'static',
      windowClass: 'small-dialog'
    });
    modalRef.componentInstance.error = error;
  }
}
